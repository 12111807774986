import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../../../context/context";
import { Link } from 'react-router-dom';
import Data from "../../../../api/data.json"

const imgUrl = require.context('../../../../img/', true);

const ChoseArea = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <section className="tp-choose-area position-relative mt-120">
            <div className="tp-choose-yellow-bg"></div>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-xl-6 col-lg-8">
                        <div className="tp-choose z-index">
                            <div className="tp-section-title-wrapper mt-60 wow fadeInUp" data-wow-delay=".2s">
                                <h5 className="tp-section-subtitle common-white-shape section__sm__title text-white">Why Choose {rpdata?.dbPrincipal?.name}</h5>
                                <h1 className="text-white"> {rpdata.dbSlogan?.[5].slogan}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-12">
                        <div className="tp-choose-img mb-30 z-index d-none d-xl-block wow fadeInUp" data-wow-delay=".6s">
                            <img src={rpdata?.gallery?.[6]} alt="img not found" className="img-call-to-action"/>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
        </>
    );
};

export default ChoseArea;