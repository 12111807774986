import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../../../context/context";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Data from "../../../../api/data.json";

const imgUrl = require.context("../../../../img/", true);
const BannerArea = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="brand-area pt-80 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 text-center content-directorios"></div>
            <div className="col-lg-4 col-md-6 text-center content-directorios">
              <a href={rpdata?.gmb?.link} target="_blank">
                <img src={rpdata?.gmb?.img} style={{ width: "75%" }} />
              </a>
            </div>
            <div className="col-lg-4 col-md-6 text-center content-directorios"></div>
          </div>
          <h1 className="text-center text-black">Find Us On</h1>
          <div className="brand-active swiper-container">
            <div className="row justify-content-center pd-top-20">
              {/* {rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-6 text-center content-directorios"
                    key={index}
                  >
                    <a href={item.url} target="_blank">
                      <img src={item.image}></img>
                    </a>
                  </div>
                );
              })} */}
              {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-6 text-center content-directorios"
                    key={index}
                  >
                    <a href={item.url} target="_blank">
                      <img src={item.image}></img>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div className="container pt-100">
            <div className="row">
              <div className="col-lg-4 col-md-6 text-center content-directorios">
                <a href="https://images.sherwin-williams.com/content_images/sw-pdf-sherwin-williams-colorc.pdf" target="_blank">
                  <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2FSW-1.jpg?alt=media&token=288b0d1c-3606-4e99-98a9-5f3539cd0081" alt="Sherwin Williams"/>
                </a>
              </div>
              <div className="col-lg-4 col-md-6 text-center content-directorios">
                <a href="https://www.benjaminmoore.com/en-us/color-overview/color-palettes" target="_blank">
                  <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2Fbjm-1.jpg?alt=media&token=30c85c07-dcc1-4601-81cf-40186bbc014b"  alt="Benjamin Moore"/>
                </a>
              </div>
              <div className="col-lg-4 col-md-6 text-center content-directorios">
                <a href="https://www.behr.com/consumer/colors/paint/" target="_blank">
                  <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2Fbehr-1.jpg?alt=media&token=5b20facf-8923-4bad-9c02-b2d6d9f5b44e" alt="Behr" />
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default BannerArea;
